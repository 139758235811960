@keyframes wiggle {
  0%   {transform: translateX(0);}
  50%  {transform: translateX(-8px);}
  75%  {transform: translateX(3px);}
  100% {transform: translateX(0);}
}

@keyframes fadeIn {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
    background: rgba(255, 0, 0, 0);
  }
  25% {transform: translateX(10px);}
  50% {
    background: rgba(255, 0, 0, 0.3);
  }
  75% {transform: translateX(-10px);}
  100% {
    transform: translateX(0);
    background: rgba(255, 0, 0, 0);
  }
}

@keyframes blinkRed {
    0% {
      background: rgba(255, 0, 0, 0);
    }
    25% {
      background: rgba(255, 0, 0, 0.3);
    }
    75% {
      background: rgba(255, 0, 0, 0.3);
    }
    100% {
      background: rgba(255, 0, 0, 0);
    }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.03);
  }
  50% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}
