@font-face {
    font-family: 'DMSans';
    src: local('DMSans'), url(../fonts/DMSans-Medium.ttf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DMSans';
    src: local('DMSans'), url(../fonts/DMSans-Bold.ttf);
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DMSans';
    src: local('DMSans'), url(../fonts/DMSans-BoldItalic.ttf);
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'DMSans';
    src: local('DMSans'), url(../fonts/DMSans-Italic.ttf);
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'DMSans';
    src: local('DMSans'), url(../fonts/DMSans-MediumItalic.ttf);
    font-weight: normal;
    font-style: italic;
}