:root {
  color: #444444;
  --primary: #f9afa2;
  --primary2: #f9cbc3;
  --primary3: #ffeeeb;
  --primary4: #fff8f7;
  --secondary: hsl(237, 20%, 35%);
  --secondaryDark: rgb(188, 188, 193);
  --secondaryLight: hsl(238, 30%, 57%);
  --bgColor: white;
  --grey: #999;
  --darkText: #181b46;
  --red: rgb(189, 54, 54);
  --padding: 15px;
  --borderWidth: 3px;
  --borderRadius: 15px;
  --availableTime: var(--primary);
  --text: hsl(0, 0%, 95%);
  --green1: rgb(155, 255, 155);
  --green2: rgb(213, 255, 213);
  --blue1: rgb(90, 90, 206);
  --blue2: rgb(114, 114, 214);
  --blue3: rgb(138, 138, 219);
  --blue4: #a9aef0;
  --blue5: #EAECFF;

  --redwine: #981f62;
  --redwineLight: #d9bdcf;
  --yellow: #de9e4d;
  --orange: #f96041;
  
  --mobileWidth: 600px;

  /* Psychologist colors*/
  --turquoise: #03a49c;
  --lightTurquoise: #C8DFDF;
  --turquoiseOpacity: rgba(3, 164, 156, 0.1);
  --cabanaBay: #8dc5c2;
  --lightGreen: #e8f3f3;
}
* {
  /*This should be default in CSS for real*/
  box-sizing: border-box;
  font-family: 'DMSans';
}
a {
  color: unset;
}
body {
  position: relative;
  width: 100%;
  margin: 0;
  color: var(--text);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 80vh;
  padding: 2rem;
}

.page {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  min-height: 80vh;
  color: var(--darkText);
}
.psychologistPageColors {
  background-color: var(--lightGreen);

  button {
    background-color: var(--turquoise);
    &:hover {
      background-color:lighten($color: #03a49c, $amount: 5);
    }
  }

}
.pageTitleBar {
  width: 100%;
  padding: 15px 1.5rem;
  font-size: 1.1em;
  color: var(--secondary);
}
.columnFlex {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .noPaddingMobile {
    padding-right: 0;
    padding-left: 0;
  }
}
.roundBox {
  border-radius: var(--borderRadius);
  border: var(--borderWidth) solid var(--primary);
  padding: var(--padding);
}
.borderBottomBox {
  border: 0;
  border-bottom: 1px solid grey;
  padding: var(--padding);
}


.text {
  color: var(--darkText);
}
.whiteText {
  color: white;
}
.secondary {
  color: var(--secondary)
}
.darkText {
  color: var(--darkText)
}
.shadow {
  box-shadow: 0 0 6px 0 gray;
}
.brightHover {
  transition: box-shadow 250ms;
  &:hover {
    box-shadow: inset 0 0 100rem 0rem rgba(255, 255, 255, 0.3);
  }
}
.darkHover {
  transition: box-shadow 250ms;
  &:hover {
    box-shadow: inset 0 0 100rem 0rem rgba(0, 0, 0, 0.1);
  }
}
.fullWidth {
  width: 100%;
}
.itemsCenter {
  align-items: center;
}

.justifyStart {
  justify-content: flex-start;
}
.spread {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.padding {
  padding: var(--padding);
}
.padding-2 {
  padding: 2rem;
}
.mr-1 {
  margin-right: 1rem;
}
.mb-0 {
  margin-bottom: 0;
}
.ml-1 {
  margin-left: 1rem;
}
.ml-2 {
  margin-left: 2rem;
}
.pl-1 {
  padding-left: 1rem;
}
.pl-2 {
  padding-left: 2rem;
}
.clickable {
  cursor: pointer;
}
.flex {
  display: flex;
}
.justifyCenter {
  justify-content: center;
}
.selfCenter {
  align-self: center;
}
.selfStart {
  align-self: flex-start;
}
.itemsStart {
  align-items: flex-start;
}
.itemsEnd {
  align-items: flex-end;
}
.underline {
  text-decoration: underline;
}
.m-0 {
  margin: 0;
}
.mb-1 {
  margin-bottom: 0.5rem;
}
.mb-2 {
  margin-bottom: 1rem;
}
.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.pl {
padding-left: var(--padding);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h1, h2, h3, h4, h5 {
  font-weight: bold;
}
h1 {
    font-size: 2.1rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.2rem;
}
h4 {
  font-size: 1rem;
  margin-bottom: 0.7rem;
}
a {
  text-decoration: none;
}

input:focus {
  outline: 0;
}
button {
  font-size: 1.0rem;
  display: flex;
  width: 200px;
  justify-content: center;
  padding: 1rem;
  transition: 200ms;
  border-radius: 2rem;
  border: 0;
  margin: 0.2rem;
  background-color: var(--secondary);
  color: var(--text);
  font-weight: bold;
  cursor: pointer;
}
button.lesserButton {
  background-color: var(--primary);
  border: 2px solid var(--secondary);
  color: var(--darkText);
}
button.ghostButton {
  background-color: transparent;
  color: var(--darkText);
  border: 2px solid var(--secondary);
}
button.lesserButton:hover {
  background-color: #d29287;
}
button:hover {
  background-color: var(--secondaryLight);
}
button:disabled {
  background-color: var(--secondaryDark);
  cursor: auto;
}
.heartBg {
  overflow: hidden;
  position: relative;
}
.heartBg::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.07;
  // background-image: url('../img/croppedHeart.svg');
  background-repeat: no-repeat;
  background-position: left bottom;
  background-origin: border-box;
  background-size: min(60vh, 50vw);
  pointer-events: none;
  z-index: -2;
}
.checkSVGCircle {
  fill: #47496b;
  height: 28px;
  width: 28px;
  margin-right: 10px;
  color: green;
  transition: 200ms;
}
.psychologistPageColors .checkSVGCircle {
  fill: #03a49c;
}
button:hover {
  /*
  background-color: var(--primary);
  color: white;*/
}
p, div, a {
  /* line-height: 1.3; */
}

@media screen and (max-width: 500px) {
  /* p {
    font-size: 18px;
  } */
}
.pageContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 800px;
}
.scaleOnHover:hover {
  transform: scale(1.04);
}
.raiseOnHover {
  position: relative;
  transition: transform 300ms;
}
.raiseOnHover:hover {
  transform: translateY(-4px);
}
.noScroll {
  overflow-y: hidden;
}
.rotate {
  transform: rotate(180deg);
}
.scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    margin: 1rem 0;
    background-color: white;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-radius: 10px;
  }
}
.appear {
  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: appear 0.5s 1  forwards;
}