@media screen and (max-width: 1000px) {
  h1 {
    font-size: x-large;
  }
  .book {
    flex-direction: column !important;
  }

  .book>div>img {
    width: 30% !important;
    margin-bottom: 60px;
  }

  .book>div>div {
    bottom: 20px !important;
  }

  .book>div>div>h1 {
    margin-bottom: 5px;
  }

  .splitView {
    padding: 20px;
    padding-bottom: 0 !important;
    flex-direction: column !important;
    flex: none !important;
  }

  .splitView>:last-of-type {
    flex: 2 !important;
  }

  .splitView>div {
    width: 100%;
  }

  .splitView p {
    margin-bottom: 0;
  }

  .splitView>div>div>h1{
    margin: 0;
  }

  .splitView>div>div{
    /* justify-content: flex-start !important; */
  }

  .scrollList {
    min-height: 220px;
  }

  .dots {
    padding: 0 !important;
  }

  .calendar {

  }

  .calendar .dateContainer {

  }

  .calendar .dateContainer>div {
    /* height: 40px; */
    /* width: 40px; */
  }

  .calendar .dateContainer>div>div {
    /* left: 2px; */
  }

}
